var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"my-0"},[_c('Alertize'),_c('v-card',{attrs:{"flat":"","justify":"between","align":"center","color":"grey lighten-3 py-4"}},[_c('v-form',{ref:"form",attrs:{"justify":"between","align":"center","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"10"}},[_c('CardTextField',{class:{ disabled: true },attrs:{"rules":[],"hint":_vm.$t('passwordReset.fields.email'),"reference":"email","label":_vm.$t('passwordReset.fields.email'),"placeholder":_vm.$t('passwordReset.fields.email'),"required":true,"disabled":true,"counter":false},model:{value:(_vm.getEmail),callback:function ($$v) {_vm.getEmail=$$v},expression:"getEmail"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"10"}},[_c('v-card',{staticClass:"px-2 mb-2",attrs:{"elevation":"0","outlined":"","tile":"","color":"transparent"}},[_c('v-text-field',{ref:"password",staticClass:"label-fixed",attrs:{"append-icon":_vm.showPass
													? 'mdi-eye'
													: 'mdi-eye-off',"type":_vm.showPass
													? 'text'
													: 'password',"label":_vm.$t('passwordReset.fields.newPassword'),"rules":[
												_vm.getRules.isRequired,
												_vm.getRules.isMinPassword,
												_vm.getRules.isMaxLength,
												_vm.getRules.isPasswordFormat,
											],"disabled":_vm.isLoading},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('passwordReset.fields.newPassword'))+" "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("*")])])]},proxy:true}]),model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"10"}},[_c('v-card',{staticClass:"px-2 mb-2",attrs:{"elevation":"0","outlined":"","tile":"","color":"transparent"}},[_c('v-text-field',{ref:"password",staticClass:"label-fixed",attrs:{"append-icon":_vm.showPass
													? 'mdi-eye'
													: 'mdi-eye-off',"type":_vm.showPass
													? 'text'
													: 'password',"label":_vm.$t('passwordReset.fields.newPasswordConf'),"rules":[
												_vm.getRules.isRequired,
												_vm.getRules.isMinPassword,
												_vm.getRules.isMaxLength,
												_vm.getRules.isPasswordFormat,
											],"disabled":_vm.isLoading},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('passwordReset.fields.newPasswordConf'))+" "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("*")])])]},proxy:true}]),model:{value:(_vm.confirm_new_password),callback:function ($$v) {_vm.confirm_new_password=$$v},expression:"confirm_new_password"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('CardMesseger')],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"ma-4"}),_c('v-row',{attrs:{"dense":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"0","outlined":"","tile":"","color":"rgb(0, 0, 0, 0.0)"}},[_c('v-card-text',{staticClass:"secondary--text info-message",attrs:{"align":"start"}},[_vm._v(" "+_vm._s(_vm.$t("common.labels.mandatory"))+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"0","outlined":"","tile":"","color":"rgb(0, 0, 0, 0.0)"}},[_c('v-row',{attrs:{"align":"end","justify":"end"}},[_c('v-btn',{staticClass:"mx-2 px-8",attrs:{"rounded":"","color":"secondary"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")]),_c('v-btn',{staticClass:"mx-2 px-8",attrs:{"type":"button","rounded":"","color":"secondary"},on:{"click":_vm.redirectTo}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }