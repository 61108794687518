
import Vue from "vue";
import Alertize from "../../../../components/Alertize.vue";
import {
	isRequired,
	isMinPassword,
	isMaxLength,
	isPasswordFormat,
} from "../../../../services/rule-services";
// @ts-ignore
import CardMesseger from "@/components/Content/CardMesseger.vue";
import {
	Notification,
	MessageTypes,
	// @ts-ignore
} from "@/interfaces/proccess";
// @ts-ignore
import i18n from "@/plugins/i18n";
// @ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
import { mapActions } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import notificationService from "@/services/notification-service";
import store from "@/store";

export default Vue.extend({
	name: "ChangePassword",
	props: {},
	components: { Alertize, CardMesseger, CardTextField },

	data: () => ({
		valid: false,
		loading: false,
		showPass: false,
		new_password: "",
		confirm_new_password: "",

		error: {
			type: MessageTypes.ERROR,
			title: i18n.t("title-failed"),
			message: i18n.t("error"),
			btn_text: i18n.t(MessageTypes.CONTINUE),
			show: true
		} as Notification
	}),

	created() {},

	async mounted() {},

	computed: {
		getID() {
			return this.getProfile?.id;
		},
		getEmail() {
			return this.getProfile?.email;
		},
		getRules() {
			return {
				isRequired,
				isMinPassword,
				isMaxLength,
				isPasswordFormat
			};
		},
		getProfile() {
			return this.$store.state.profile.profile;
		},
		isLoading() {
			return this.$store.state.proccess.loading;
		}
	},

	methods: {
		...mapActions("loading", ["setLoadingData"]),
		redirectTo() {
			this.setNotification({ title: "", message: "", type: "" });
			this.$router.push({ name: "CampaignsIndex" });
		},
		setNotification(notification: Notification) {
			return notificationService.notifyError(store, notification);
		},
		clearFields() {
			this.new_password = "";
			this.confirm_new_password = "";
		},
		async validate() {
			let form = this.$refs.form;
			return await form.validate();
		},

		async changePassword(updateData: any) {
			return this.$store
				.dispatch("auth/changePassword", updateData)
				.catch((error: any) => {
					if (error.message == "Unauthorized") {
						this.error.message =
							"The current password does not correspond to the account";
						this.setNotification(this.error);
					}
				});
		},

		async handleSubmit() {
			try {
				if (!(await this.validate())) return;

				if (this.new_password == this.confirm_new_password) {
					await this.setLoadingData(TypeLoading.loading);

					await this.changePassword({
						id: this.getProfile.id,
						password: this.new_password,
						password_confirmation: this.confirm_new_password
					});

					this.clearFields();
					await this.setLoadingData();
				} else {
					this.error.message =
						this.$t('passwordChange.errors.passwordNotMatch');
					this.setNotification(this.error);
				}
			} catch (error) {
				await this.setLoadingData();
			}
		}
	}
});
